/** @type {HTMLTemplateElement} */
const thumbnailTemplate = document.querySelector(`body > main > ol > template.thumbnail`);

class ThumbnailElement extends HTMLElement {
	constructor() {
		super();
		this.attachShadow({mode: `open`})
			.appendChild(thumbnailTemplate.content.cloneNode(true));
	}

	/**
	 * @param src {string}
	 * @param width {number}
	 * @param height {number}
	 */
	src(src, width, height) {
		const imageElement = this.shadowRoot.querySelector(`img`);
		imageElement.src = src;
		imageElement.width = width;
		imageElement.height = height;
		imageElement.style.aspectRatio = `${width} / ${height}`;
	}

	/**
	 * @param viewCount {number}
	 */
	set viewCount(viewCount) {
		const viewCountContainerElement = this.shadowRoot.querySelector(`div`);
		viewCountContainerElement.title = `${viewCount} views`;
		const viewCountElement = this.shadowRoot.querySelector(`div > span:last-of-type`);
		let formatter = Intl.NumberFormat('en', { notation: `compact` });
		viewCountElement.textContent = `${formatter.format(viewCount)}`;
	}
}

customElements.define(`recess-thumbnail`, ThumbnailElement);
